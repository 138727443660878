import React from 'react'
import { ConfigType } from '../Main'
import { DEFAULT_BGIMAGE, S3_BUCKET_URL } from '../constants'

type Props = {
  data: ConfigType
}

export const Header = ({ data }: Props) => {
  const logo = data?.logo ? S3_BUCKET_URL + '/' + data.logo : DEFAULT_BGIMAGE
  return (
    <div className="row" style={{}}>
      <div className="col-12 col-md-6 col-md-10 mx-auto">
        <div className="row" style={{}}>
          <div className="col-12 mx-auto " style={{ maxWidth: 400, minHeight: 120 }}>
            <div
              className="h-100 w-100"
              style={{
                backgroundImage: `url(${logo})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
            ></div>
          </div>

          <div className="col-12 d-flex flex-column align-items-center justify-content-center my-4">
            <h1 className="fw-bold">{data.page.headline}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}
